<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="520px"
    @close="handleClose"
  >
    <el-divider content-position="center" />
    <el-image style="width: 100%; min-height: 400px" :src="url" :fit="fit" />
    <el-button
      class="mt-20 w-percent-100"
      size="large"
      type="primary"
      @click="handleClose"
    >
      关闭
    </el-button>
  </el-dialog>
</template>
<script setup>
  import { ref } from 'vue'
  import { debounce } from '@/utils/debounce'
  const props = defineProps({
    title: {
      type: String,
      default: '预览',
    },
    url: {
      type: String,
      default:
        'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
    },
  })
  const dialogFormVisible = ref(false)
  const fit = ref('cover')
  const handleClose = () => {
    dialogFormVisible.value = false
  }
  const handleOpen = debounce(() => {
    dialogFormVisible.value = true
  })
  defineExpose({
    handleClose,
    handleOpen,
  })
</script>
